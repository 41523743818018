import React, {useState, useContext} from "react";
import { Button, Tooltip, Modal, Row, Col, Card, Typography, message } from "antd";
import { SettingFilled, ShareAltOutlined } from "@ant-design/icons";
import { WebParameterContext } from "../../contexts/webParameter";
import copy from "copy-to-clipboard";
import { getFilterStr, getSelectedAnotherChartAxis, getSelectedHeatmapChartAxis, getEditLabel, getSortTableStr, getHeatmapMapLevel } from "../../helpers";

const { Paragraph } = Typography;
const SettingModal = ({dataInfo,  appType}) =>{
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mapEmbedCode, setMapEmbedCode] = useState("");
    const [mapLink, setMapLink] = useState("");
    const [chartEmbedCode, setChartEmbedCode] = useState("");
    const [chartLink, setChartLink] = useState("");
    const [tableLink, setTableLink] = useState("");
    const {
        _page_,
        _pageSize_,
        _mainFilter_,
        _chartFilter_,
        _currBaseMap_,
        _chart_,
        _lineSort_,
        _barSort_,
        _location_,
        _scoreCardLabel_,
        _tableSorter_,
        _mapFilter_,
        _tooltipField_,
        _limitOffsetMap_,
        _chartHeatmapFilter_,
        _mapLevel_,
        _barChartType_,
        _utmLocation_,
        _cardColor_,
        _selectedLbState_,
        _selectedPieState_,
        _selectedBarState_,
        _selectedScatterTreemapState_,
        _selectedScorecardState_,
        _selectedHeatmapState_,
        _selectedSunburstState_,
        _selectedLbStateFromShare_,
        _selectedPieStateFromShare_,
        _selectedBarStateFromShare_,
        _selectedScatterTreemapStateFromShare_,
        _selectedScorecardStateFromShare_,
        _selectedHeatmapStateFromShare_,
        _selectedSunburstStateFromShare_,
        _currentMapLayer_
    } = useContext(WebParameterContext);
    let path = window.location.pathname;
    path = path.replace("/", "");
    const source = path.substring(0, path.indexOf("/"));
    const id = path.substring(path.indexOf("/") + 1, path.length);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setMapEmbedCode("");
        setMapLink("");
        setChartEmbedCode("");
        setChartLink("");
        setTableLink("")
        setIsModalOpen(false);
    };
    const generateLink = (appType) =>{
        let sharedUrl = window.location.origin + window.location.pathname;
        const filterStr = getFilterStr(_mainFilter_, "main");
        const chartFilterStr = getFilterStr(_chartFilter_, "chart");
        const mapFilterStr = getFilterStr(_mapFilter_, "map");
        const heatmapFilter =  _chart_ === "Heatmap" ? getFilterStr(_chartHeatmapFilter_, "heatmap") : "";

        const lineSort = _chart_ === "Line chart" ? `&ls=${_lineSort_}` : "";
        const barSort = _chart_ === "Bar chart" || _chart_ === "Bar chart2" ? `&bs=${_barSort_}` : "";
        const barType = _chart_ === "Bar chart" || _chart_ === "Bar chart2" ? `&bt=${_barChartType_}` : "";

        const lat = _location_.lat !== "" ? `&lat=${encodeURIComponent(_location_.lat)}` : "";
        const lng = _location_.lng !== "" ? `&lng=${encodeURIComponent(_location_.lng)}` : "";
        const utm = _utmLocation_.x !== "" && _utmLocation_.y !== "" && _utmLocation_.zone !== "" ? `&utm=${encodeURIComponent(JSON.stringify(_utmLocation_))}` : "";
        let selectedLbStr = _chart_ === "Line chart" || _chart_ === "Bar chart" ? getSelectedAnotherChartAxis(_selectedLbState_, "linebar") : "";

        // let selectedPieBarStr = _chart_ === "Pie chart" || _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedPieBarState_, "pie") : "";
        let selectedPieStr = _chart_ === "Pie chart" ? getSelectedAnotherChartAxis(_selectedPieState_, "pie") : "";
        let selectedBarStr = _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedBarState_, "bar") : "";
        let selectedScatterTreemapStr = _chart_ === "Scatter chart" || _chart_ === "Treemap chart" ? getSelectedAnotherChartAxis(_selectedScatterTreemapState_, _chart_ === "Scatter chart" ? "scatter" : "treemap") : "";
        let selectedScorecardStr = _chart_ === "Scorecard" ? getSelectedAnotherChartAxis(_selectedScorecardState_, "scorecard") : "";
        let selectedSunburstStr = _chart_ === "Sunburst chart" ? getSelectedAnotherChartAxis(_selectedSunburstState_, "sunburst") : "";
        let selectedHeatmapStr = _chart_ === "Heatmap" ? getSelectedHeatmapChartAxis(_selectedHeatmapState_) : ""
        const mapLevelStr = _chart_ === "Heatmap" ? getHeatmapMapLevel(_mapLevel_) : ""
        let scoreCardLabel = "", color = "";
        if(_chart_ === "Scorecard"){
            scoreCardLabel = getEditLabel(_scoreCardLabel_)
            color = `&color=${JSON.stringify([_cardColor_.background, _cardColor_.title, _cardColor_.number])}`
        }
        const searchParams = new URLSearchParams(window.location.search);
        //also handle when share from shared link
        if(searchParams.has("linebar")) {
            selectedLbStr = _chart_ === "Line chart" || _chart_ === "Bar chart" ? getSelectedAnotherChartAxis(_selectedLbStateFromShare_, "linebar") : "";
        }
        // if(searchParams.has("pie") || searchParams.has("bar")) {
        //     selectedPieBarStr = _chart_ === "Pie chart" || _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedPieBarStateFromShare_, "pie") : "";
        // }
        if(searchParams.has("pie")) {
            selectedPieStr = _chart_ === "Pie chart" ? getSelectedAnotherChartAxis(_selectedPieStateFromShare_, "pie") : "";
        }
        if(searchParams.has("bar")) {
            selectedBarStr = _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedBarStateFromShare_, "bar") : "";
        }
    
        if(searchParams.has("scatter") || searchParams.has("treemap")) {
            selectedScatterTreemapStr = _chart_ === "Scatter chart" || _chart_ === "Treemap chart" ? getSelectedAnotherChartAxis(_selectedScatterTreemapStateFromShare_, _chart_ === "Scatter chart" ? "scatter" : "treemap") : "";
        }
        if(searchParams.has("scorecard")) {
            selectedScorecardStr = _chart_ === "Scorecard" ? getSelectedAnotherChartAxis(_selectedScorecardStateFromShare_, "scorecard") : "";
        }
        if(searchParams.has("sunburst")) {
            selectedSunburstStr = _chart_ === "Sunburst chart" ? getSelectedAnotherChartAxis(_selectedSunburstStateFromShare_, "sunburst") : "";
        }
        if(searchParams.has("heatmap")) {
            selectedHeatmapStr = _chart_ === "Heatmap" ? getSelectedHeatmapChartAxis(_selectedHeatmapStateFromShare_) : "";
        }
        const sortTableStr = getSortTableStr(_tableSorter_)
        if(appType === "Map"){
            const mapBase = `?map&base=${_currBaseMap_}`;
            sharedUrl += `${mapBase}${lat}${lng}${utm}${mapFilterStr}${_tooltipField_.length > 0 ? `&field=${encodeURIComponent(JSON.stringify(_tooltipField_))}` : ""}&mlimit=${_limitOffsetMap_.limit}&moffset=${_limitOffsetMap_.offset}&layer=${encodeURIComponent(_currentMapLayer_)}`;
        }
        else if(appType === "Chart"){
            sharedUrl += `?chart&charttype=${_chart_}${lineSort}${barSort}${barType}${chartFilterStr}${heatmapFilter}${selectedLbStr}${selectedPieStr}${selectedBarStr}${selectedScatterTreemapStr}${selectedScorecardStr}${selectedHeatmapStr}${selectedSunburstStr}${scoreCardLabel?scoreCardLabel:""}${color}${mapLevelStr}`;
        }
        else{
            sharedUrl += `?limit=${_pageSize_}&offset=${(_page_ - 1) * _pageSize_}${filterStr}${sortTableStr?sortTableStr:""}`;
        }
        sharedUrl = encodeURI(sharedUrl);

        return sharedUrl
    }
    
    const copySharableLink = () => {
        let sharedUrl = window.location.origin + window.location.pathname;
        const filterStr = getFilterStr(_mainFilter_, "main");
        const chartFilterStr = getFilterStr(_chartFilter_, "chart");
        const mapFilterStr = getFilterStr(_mapFilter_, "map");
        const heatmapFilter = getFilterStr(_chartHeatmapFilter_, "heatmap");
    
        const lineSort = `&ls=${_lineSort_}`;
        const barSort = `&bs=${_barSort_}`;
        const barType = `&bt=${_barChartType_}`;
        const lat = _location_.lat !== "" ? `&lat=${encodeURIComponent(_location_.lat)}` : "";
        const lng = _location_.lng !== "" ? `&lng=${encodeURIComponent(_location_.lng)}` : "";
        const utm = _utmLocation_.x !== "" && _utmLocation_.y !== "" && _utmLocation_.zone !== "" ? `&utm=${encodeURIComponent(JSON.stringify(_utmLocation_))}` : "";
        const mapBase = `&base=${_currBaseMap_}`;
    
        let selectedLbStr = getSelectedAnotherChartAxis(_selectedLbState_, "linebar");
        // let selectedPieBarStr = getSelectedAnotherChartAxis(_selectedPieBarState_, "pie");
        let selectedPieStr = getSelectedAnotherChartAxis(_selectedPieState_, "pie");
        let selectedBarStr = getSelectedAnotherChartAxis(_selectedBarState_, "bar");
        let selectedScatterStr = getSelectedAnotherChartAxis(_selectedScatterTreemapState_, "scatter");
        let selectedTreemapStr = getSelectedAnotherChartAxis(_selectedScatterTreemapState_, "treemap");
        let selectedScorecardStr = getSelectedAnotherChartAxis(_selectedScorecardState_, "scorecard");
        let selectedSunburstStr = getSelectedAnotherChartAxis(_selectedSunburstState_, "sunburst");
        let selectedHeatmapStr = getSelectedHeatmapChartAxis(_selectedHeatmapState_)
        const mapLevelStr = getHeatmapMapLevel(_mapLevel_)
        const searchParams = new URLSearchParams(window.location.search);
        //also handle when share from shared link
        if(searchParams.has("linebar")) {
            selectedLbStr = getSelectedAnotherChartAxis(_selectedLbStateFromShare_, "linebar");
        }
        if(searchParams.has("pie")) {
            selectedPieStr = getSelectedAnotherChartAxis(_selectedPieStateFromShare_, "pie");
        }
        if(searchParams.has("bar")) {
            selectedBarStr = getSelectedAnotherChartAxis(_selectedBarStateFromShare_, "bar");
        }
        if(searchParams.has("scatter")) {
            selectedScatterStr = getSelectedAnotherChartAxis(_selectedScatterTreemapStateFromShare_, "scatter");
        }
        if(searchParams.has("treemap")) {
            selectedTreemapStr = getSelectedAnotherChartAxis(_selectedScatterTreemapStateFromShare_, "treemap");
        }
        if(searchParams.has("scorecard")) {
            selectedScorecardStr = getSelectedAnotherChartAxis(_selectedScorecardStateFromShare_, "scorecard");
        }
        if(searchParams.has("sunburst")) {
            selectedSunburstStr = getSelectedAnotherChartAxis(_selectedSunburstStateFromShare_, "sunburst");
        }
        if(searchParams.has("heatmap")) {
            selectedHeatmapStr = getSelectedHeatmapChartAxis(_selectedHeatmapStateFromShare_);
        }
        let scoreCardLabel = getEditLabel(_scoreCardLabel_)
        let color = `&color=${JSON.stringify([_cardColor_.background, _cardColor_.title, _cardColor_.number])}`
        const sortTableStr = getSortTableStr(_tableSorter_)
    
        sharedUrl += `?limit=${_pageSize_}&offset=${
          (_page_ - 1) * _pageSize_
        }${filterStr}${mapFilterStr}&charttype=${_chart_}${lineSort}${barSort}${barType}${chartFilterStr}${heatmapFilter}${selectedLbStr}${selectedPieStr}${selectedBarStr}${selectedScatterStr}${selectedTreemapStr}${selectedScorecardStr}${selectedHeatmapStr}${selectedSunburstStr}${mapBase}${lat}${lng}${utm}${scoreCardLabel?scoreCardLabel:""}${color}${sortTableStr?sortTableStr:""}${mapLevelStr}${_tooltipField_.length > 0 ? `&field=${encodeURIComponent(JSON.stringify(_tooltipField_))}` : ""}&mlimit=${_limitOffsetMap_.limit}&moffset=${_limitOffsetMap_.offset}&layer=${encodeURIComponent(_currentMapLayer_)}`;
        sharedUrl = encodeURI(sharedUrl);
    
        copy(sharedUrl);
        message.success("คัดลอกลิงก์สำหรับแชร์แล้ว", 1);
    };
    const createMapEmbedCode = () => {
        const mapBase = `?map&base=${_currBaseMap_}`;
        const lat = _location_.lat !== "" ? `&lat=${encodeURIComponent(_location_.lat)}` : "";
        const lng = _location_.lng !== "" ? `&lng=${encodeURIComponent(_location_.lng)}` : "";
        const utm = _utmLocation_.x !== "" && _utmLocation_.y !== "" && _utmLocation_.zone !== "" ? `&utm=${encodeURIComponent(JSON.stringify(_utmLocation_))}` : "";
        // const limit = `&limit=${_pageSize_}`;
        // const offset = `&offset=${(_page_ - 1) * _pageSize_}`;
        // const filterStr = getFilterStr(_mainFilter_, "main");
        // let embed = `${window.location.origin}${window.location.pathname}${mapBase}${lat}${lng}${limit}${offset}${filterStr}`;
        const mapFilterStr = getFilterStr(_mapFilter_, "map");
        let embed = `${window.location.origin}${window.location.pathname}${mapBase}${lat}${lng}${utm}${mapFilterStr}${_tooltipField_.length > 0 ? `&field=${encodeURIComponent(JSON.stringify(_tooltipField_))}` : ""}&mlimit=${_limitOffsetMap_.limit}&moffset=${_limitOffsetMap_.offset}&layer=${encodeURIComponent(_currentMapLayer_)}`;
    
        embed = encodeURI(embed);
    
        const code = `<iframe src="${embed}" height="100%" width="100%"/>`;
        setMapEmbedCode(code);
      };
      const createMapLink = () => {
        const mapBase = `?map&base=${_currBaseMap_}`;
        const lat = _location_.lat !== "" ? `&lat=${encodeURIComponent(_location_.lat)}` : "";
        const lng = _location_.lng !== "" ? `&lng=${encodeURIComponent(_location_.lng)}` : "";
        const utm = _utmLocation_.x !== "" && _utmLocation_.y !== "" && _utmLocation_.zone !== "" ? `&utm=${encodeURIComponent(JSON.stringify(_utmLocation_))}` : "";
        // const limit = `&limit=${_pageSize_}`;
        // const offset = `&offset=${(_page_ - 1) * _pageSize_}`;
        // const filterStr = getFilterStr(_mainFilter_, "main");
        // let embed = `${window.location.origin}${window.location.pathname}${mapBase}${lat}${lng}${limit}${offset}${filterStr}`;
        const mapFilterStr = getFilterStr(_mapFilter_, "map");
        let embed = `${window.location.origin}${window.location.pathname}${mapBase}${lat}${lng}${utm}${mapFilterStr}${_tooltipField_.length > 0 ? `&field=${encodeURIComponent(JSON.stringify(_tooltipField_))}` : ""}&mlimit=${_limitOffsetMap_.limit}&moffset=${_limitOffsetMap_.offset}&layer=${encodeURIComponent(_currentMapLayer_)}`;
    
        embed = encodeURI(embed);
    
        const code = `${embed}`;
        setMapLink(code);
      };
    
      const createChartEmbedCode = () => {
        const chartFilterStr = getFilterStr(_chartFilter_, "chart");
        const heatmapFilter =  _chart_ === "Heatmap" ? getFilterStr(_chartHeatmapFilter_, "heatmap") : "";
        let selectedLbStr = _chart_ === "Line chart" || _chart_ === "Bar chart" ? getSelectedAnotherChartAxis(_selectedLbState_, "linebar") : "";

        // let selectedPieBarStr = _chart_ === "Pie chart" || _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedPieBarState_, _chart_ === "Pie chart" ? "pie" : "bar") : "";
        let selectedPieStr = _chart_ === "Pie chart" ? getSelectedAnotherChartAxis(_selectedPieState_, "pie") : "";
        let selectedBarStr = _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedBarState_, "bar") : "";
        let selectedScatterStr = _chart_ === "Scatter chart" ? getSelectedAnotherChartAxis(_selectedScatterTreemapState_, "scatter") : "";
        let selectedTreemapStr = _chart_ === "Treemap chart" ? getSelectedAnotherChartAxis(_selectedScatterTreemapState_, "treemap") : "";
        let selectedScorecardStr = _chart_ === "Scorecard" ? getSelectedAnotherChartAxis(_selectedScorecardState_, "scorecard") : "";
        let selectedHeatmapStr = _chart_ === "Heatmap" ? getSelectedHeatmapChartAxis(_selectedHeatmapState_) : ""
        let selectedSunburstStr = _chart_ === "Sunburst chart" ? getSelectedAnotherChartAxis(_selectedSunburstState_, "sunburst") : "";
        const searchParams = new URLSearchParams(window.location.search);
        //also handle when share from shared link
        if(searchParams.has("linebar")) {
            selectedLbStr = _chart_ === "Line chart" || _chart_ === "Bar chart" ? getSelectedAnotherChartAxis(_selectedLbStateFromShare_, "linebar") : "";
        }
        //bar chart2 share selected state with pie
        // if(searchParams.has("pie")) {
        //     selectedPieBarStr = _chart_ === "Pie chart" || _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedPieBarStateFromShare_, _chart_ === "Pie chart" ? "pie" : "bar") : "";
        // }
        if(searchParams.has("pie")) {
            selectedPieStr = _chart_ === "Pie chart" ? getSelectedAnotherChartAxis(_selectedPieStateFromShare_, "pie") : "";
        }
        if(searchParams.has("bar")) {
            selectedBarStr = _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedBarStateFromShare_, "bar") : "";
        }
        if(searchParams.has("scatter")) {
            selectedScatterStr = _chart_ === "Scatter chart" ? getSelectedAnotherChartAxis(_selectedScatterTreemapStateFromShare_, "scatter") : "";
        }
        if(searchParams.has("treemap")) {
            selectedTreemapStr = _chart_ === "Treemap chart" ? getSelectedAnotherChartAxis(_selectedScatterTreemapStateFromShare_, "treemap") : "";
        }
        if(searchParams.has("scorecard")) {
            selectedScorecardStr = _chart_ === "Scorecard" ? getSelectedAnotherChartAxis(_selectedScorecardStateFromShare_, "scorecard") : "";
        }
        if(searchParams.has("heatmap")) {
            selectedHeatmapStr = _chart_ === "Heatmap" ? getSelectedHeatmapChartAxis(_selectedHeatmapStateFromShare_) : "";
        }
        if(searchParams.has("sunburst")) {
            selectedSunburstStr = _chart_ === "Sunburst chart" ? getSelectedAnotherChartAxis(_selectedSunburstStateFromShare_, "sunburst") : "";
        }
        let scoreCardLabel = "",color = "";
        if(_chart_ === "Scorecard"){
          scoreCardLabel = getEditLabel(_scoreCardLabel_)
          color = `&color=${JSON.stringify([_cardColor_.background, _cardColor_.title, _cardColor_.number])}`
        }
        const mapLevelStr =  _chart_ === "Heatmap" ? getHeatmapMapLevel(_mapLevel_) : ""
    
        const lineSort = _chart_ === "Line chart" ? `&ls=${_lineSort_}` : "";
        const barSort = _chart_ === "Bar chart" || _chart_ === "Bar chart2" ? `&bs=${_barSort_}` : "";
        const barType = _chart_ === "Bar chart" || _chart_ === "Bar chart2" ? `&bt=${_barChartType_}` : "";
    
        let embed = `${window.location.origin}${window.location.pathname}?chart&charttype=${_chart_}${lineSort}${barSort}${barType}${chartFilterStr}${heatmapFilter}${selectedLbStr}${selectedPieStr}${selectedBarStr}${selectedScatterStr}${selectedTreemapStr}${selectedScorecardStr}${selectedHeatmapStr}${selectedSunburstStr}${scoreCardLabel?scoreCardLabel:""}${color}${mapLevelStr}`;
    
        embed = encodeURI(embed);
    
        const code = `<iframe src="${embed}" height="100%" width="100%"/>`;
        setChartEmbedCode(code);
      };
    
      const createChartLink = () => {
        const chartFilterStr = getFilterStr(_chartFilter_, "chart");
        const heatmapFilter = _chart_ === "Heatmap" ? getFilterStr(_chartHeatmapFilter_, "heatmap") : "";
        let selectedLbStr = _chart_ === "Line chart" || _chart_ === "Bar chart" ? getSelectedAnotherChartAxis(_selectedLbState_, "linebar") : "";
        // let selectedPieBarStr = _chart_ === "Pie chart" || _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedPieBarState_, _chart_ === "Pie chart" ? "pie" : "bar") : "";
        let selectedPieStr = _chart_ === "Pie chart" ? getSelectedAnotherChartAxis(_selectedPieState_,  "pie") : "";
        let selectedBarStr = _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedBarState_,  "bar") : "";
        let selectedScatterStr = _chart_ === "Scatter chart" ? getSelectedAnotherChartAxis(_selectedScatterTreemapState_, "scatter") : "";
        let selectedTreemapStr = _chart_ === "Treemap chart" ? getSelectedAnotherChartAxis(_selectedScatterTreemapState_, "treemap") : "";
        let selectedScorecardStr = _chart_ === "Scorecard" ? getSelectedAnotherChartAxis(_selectedScorecardState_, "scorecard") : "";
        let selectedSunburstStr = _chart_ === "Sunburst chart" ? getSelectedAnotherChartAxis(_selectedSunburstState_, "sunburst") : "";
        let selectedHeatmapStr = _chart_ === "Heatmap" ? getSelectedHeatmapChartAxis(_selectedHeatmapState_) : ""
        const searchParams = new URLSearchParams(window.location.search);
        //also handle when share from shared link
        if(searchParams.has("linebar")) {
            selectedLbStr = _chart_ === "Line chart" || _chart_ === "Bar chart" ? getSelectedAnotherChartAxis(_selectedLbStateFromShare_, "linebar") : "";
        }
        // if(searchParams.has("pie")) {//from share link has pie only, bar chart2 use from pie but separate param for embed link
        //     selectedPieBarStr = _chart_ === "Pie chart" || _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedPieBarStateFromShare_, _chart_ === "Pie chart" ? "pie" : "bar") : "";
        // }
        if(searchParams.has("pie")) {
            selectedPieStr = _chart_ === "Pie chart" ? getSelectedAnotherChartAxis(_selectedPieStateFromShare_, "pie") : "";
        }
        if(searchParams.has("bar")) {
            selectedBarStr = _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedBarStateFromShare_, "bar") : "";
        }
        if(searchParams.has("scatter")) {
            selectedScatterStr = _chart_ === "Scatter chart" ? getSelectedAnotherChartAxis(_selectedScatterTreemapStateFromShare_, "scatter") : "";
        }
        if(searchParams.has("treemap")) {
            selectedTreemapStr = _chart_ === "Treemap chart" ? getSelectedAnotherChartAxis(_selectedScatterTreemapStateFromShare_, "treemap") : "";
        }
        if(searchParams.has("scorecard")) {
            selectedScorecardStr = _chart_ === "Scorecard" ? getSelectedAnotherChartAxis(_selectedScorecardStateFromShare_, "scorecard") : "";
        }
        if(searchParams.has("sunburst")) {
            selectedSunburstStr = _chart_ === "Sunburst chart" ? getSelectedAnotherChartAxis(_selectedSunburstStateFromShare_, "sunburst") : "";
        }
        if(searchParams.has("heatmap")) {
            selectedHeatmapStr = _chart_ === "Heatmap" ? getSelectedHeatmapChartAxis(_selectedHeatmapStateFromShare_) : "";
        }
        let scoreCardLabel = "", color = "";
        if(_chart_ === "Scorecard"){
          scoreCardLabel = getEditLabel(_scoreCardLabel_)
          color = `&color=${JSON.stringify([_cardColor_.background, _cardColor_.title, _cardColor_.number])}`
        }
        const mapLevelStr = _chart_ === "Heatmap" ? getHeatmapMapLevel(_mapLevel_) : ""
        const lineSort = _chart_ === "Line chart" ? `&ls=${_lineSort_}` : "";
        const barSort = _chart_ === "Bar chart" || _chart_ === "Bar chart2" ? `&bs=${_barSort_}` : "";
        const barType = _chart_ === "Bar chart" || _chart_ === "Bar chart2" ? `&bt=${_barChartType_}` : "";
        
    
        let embed = `${window.location.origin}${window.location.pathname}?chart&charttype=${_chart_}${lineSort}${barSort}${barType}${chartFilterStr}${heatmapFilter}${selectedLbStr}${selectedPieStr}${selectedBarStr}${selectedScatterStr}${selectedTreemapStr}${selectedScorecardStr}${selectedHeatmapStr}${selectedSunburstStr}${scoreCardLabel?scoreCardLabel:""}${color}${mapLevelStr}`;
    
        embed = encodeURI(embed);
    
        const code = `${embed}`;
        setChartLink(code);
      };
      const getSourceLink = (appType) =>{
        let splitUrl = dataInfo.value.ref_url.split("?")
        let currentLink = generateLink(appType)
        currentLink = encodeURIComponent(currentLink)
        let newUrl = `${splitUrl[0]}/resource/${splitUrl[1].split("=")[1]}/new_view?view_type=webpage_view&playground_url=${currentLink}`
        window.open(newUrl, '_blank')
      }
      const createTableLink = () =>{
        let sharedUrl = window.location.origin + window.location.pathname;
        const filterStr = getFilterStr(_mainFilter_, "main");
        const sortTableStr = getSortTableStr(_tableSorter_)
    
        sharedUrl += `?table&limit=${_pageSize_}&offset=${(_page_ - 1) * _pageSize_}${filterStr}${sortTableStr?sortTableStr:""}`;
        sharedUrl = encodeURI(sharedUrl);
        setTableLink(sharedUrl)
      }
    return(
        <>
            <Tooltip placement="bottomLeft" title="ตั้งค่า">
                <Button
                  icon={<SettingFilled />}
                  type="primary"
                  ghost
                  size="small"
                  onClick={showModal}
                  style={{ marginTop: -12, marginRight: 5 }}
                />
            </Tooltip>
            <Modal
                title="ตั้งค่า"
                open={isModalOpen}
                onOk={handleOk}
                centered
                onCancel={handleCancel}
                maskClosable={false}
                bodyStyle={{paddingTop: 10, paddingBottom: 10}}
                footer={[
                <Button key="back" onClick={handleCancel}>
                    ปิด
                </Button>]}
            >
                <Row
                    align="middle"
                    style={{  marginBottom: 10 }}
                >
                    <Col style={{width: '100%'}}>
                        <Card title="ข้อมูลทั่วไป" bordered={false} style={{ textAlign: "left" }} headStyle={{minHeight: '32px'}} bodyStyle={{ paddingBottom: 0, paddingTop: 10 }}>
                            <Paragraph>
                                <b>ชื่อ:</b> {dataInfo.value.package_title}[
                                {dataInfo.value.resource_title}]
                            </Paragraph>
                            <Paragraph>
                                <b>ที่มา:</b> {source}
                            </Paragraph>
                            <Paragraph>
                                <b>ไอดี:</b> {id}
                            </Paragraph>
                            <Paragraph style={{marginBottom: 0}}>
                                <b>ลิงก์อ้างอิง:</b>{" "}
                                <a href={dataInfo.value.ref_url} rel="noopener noreferrer" target="_blank">{dataInfo.value.ref_url}</a>
                            </Paragraph>
                        </Card>
                    </Col>
                </Row>
                <Row align="middle" style={{ marginBottom: 10 }}>
                    <Col style={{width: '100%'}}>
                        <Card title="การแชร์" bordered={false} style={{ textAlign: "left" }} bodyStyle={{ paddingBottom: 0, paddingTop: 10 }} headStyle={{minHeight: '32px'}}>
                            <Button
                            type="primary"
                            icon={<ShareAltOutlined />}
                            onClick={copySharableLink}
                            style={{width: '250px'}}
                            >
                            คัดลอกลิงค์สำหรับแชร์เพลกราวด์
                            </Button>
                            <br />
                            {
                               (appType!== "Chart" && appType !== "Map") &&
                            <Button
                                type="primary"
                                style={{ marginTop: 20, width: '250px' }}
                                onClick={createTableLink}
                            >
                                ลิงค์สำหรับแสดงผล
                            </Button>
                            }
                            {tableLink !== "" && (
                                <Card style={{ marginTop: 10 }} bodyStyle={{padding:10}}>
                                    <Paragraph copyable style={{ margin: 0 }}>
                                        {tableLink}
                                    </Paragraph>
                                </Card>
                            )}
                        </Card>
                    </Col>
                </Row>
                {appType &&
                    <Row align="middle" style={{marginBottom: 10}}>
                        <Col style={{width: '100%'}}>
                            <Card
                                bordered={false}
                                title={appType=== "Map" ? 'การฝังสำหรับแผนที่' : 'การฝังสำหรับแผนภูมิ'}
                                style={{ textAlign: "left" }}
                                bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
                                headStyle={{minHeight: '32px'}}
                            >
                                {
                                    appType === "Map" ?
                                    <>
                                    <Button
                                        type="primary"
                                        style={{ marginTop: 10, width: '250px' }}
                                        onClick={createMapEmbedCode}
                                    >
                                        สร้างโค้ดสำหรับฝัง
                                    </Button>
                                    {mapEmbedCode !== "" && (
                                        <Card style={{ marginTop: 10 }} bodyStyle={{padding:10}}>
                                            <Paragraph copyable style={{ margin: 0 }}>
                                                {mapEmbedCode}
                                            </Paragraph>
                                        </Card>
                                    )}
                                    <br />
                                    <Button
                                        type="primary"
                                        style={{ marginTop: 20, width: '250px' }}
                                        onClick={createMapLink}
                                    >
                                        ลิงค์สำหรับแสดงผล
                                    </Button>
                                    {mapLink !== "" && (
                                        <Card style={{ marginTop: 10 }} bodyStyle={{padding:10}}>
                                            <Paragraph copyable style={{ margin: 0 }}>
                                                {mapLink}
                                            </Paragraph>
                                        </Card>
                                    )}
                                    </>
                                    :
                                    <>
                                        <Button
                                            type="primary"
                                            style={{ marginTop: 10, width: '250px' }}
                                            onClick={createChartEmbedCode}
                                        >
                                            สร้างโค้ดสำหรับฝัง
                                        </Button>
                                        {chartEmbedCode !== "" && (
                                            <Card style={{ marginTop: 10 }} bodyStyle={{padding:10}}>
                                                <Paragraph copyable style={{ margin: 0 }}>
                                                    {chartEmbedCode}
                                                </Paragraph>
                                            </Card>
                                        )}
                                        <br />
                                        <Button
                                            type="primary"
                                            style={{ marginTop: 20, width: '250px' }}
                                            onClick={createChartLink}
                                        >
                                            ลิงค์สำหรับแสดงผล
                                        </Button>
                                        {chartLink !== "" && (
                                            <Card style={{ marginTop: 10 }} bodyStyle={{padding:10}}>
                                                <Paragraph copyable style={{ margin: 0 }}>
                                                    {chartLink}
                                                </Paragraph>
                                            </Card>
                                        )}
                                    </>
                                }
                            </Card>
                        </Col>
                    </Row>
                    
                }
                <Row align="middle" style={{ marginBottom: 10 }}>
                    <Col style={{width: '100%'}}>
                        <div style={{ marginTop: 10, padding: '0px 24px' }}>
                            <Button type="primary" style={{width: '250px'}} onClick={()=>getSourceLink(appType)} >บันทึกกลับไปที่บัญชีข้อมูล</Button>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
export default SettingModal