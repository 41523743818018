import React, { useContext, useEffect, useState } from "react";
// import { Row, Col, Menu } from "antd";
// import {
//   MailOutlined,
//   AppstoreOutlined,
//   SettingOutlined,
// } from "@ant-design/icons";
import { Chart, Point, Legend, Axis, Slider, Tooltip } from "bizcharts";
// import { WebParameterContext } from "../../../contexts/webParameter";
import { getFormatNumber } from "../../../utils/formatNumber";
import { DataContext } from "../../../contexts/data";
import _ from "lodash"
import DataSet from "@antv/data-set";

/* const titleX = {
  style: {
    fontSize: 16,
    textAlign: "center",
    fill: "#777",
    fontWeight: "bold",
  },
  offset: 100,
};
const titleY = {
  style: {
    fontSize: 16,
    textAlign: "center",
    fill: "#777",
    fontWeight: "bold",
  },
}; */

const ScatterChart = ({ data, stateX, stateY }) => {
  // const { _embed_, _chartInfoEmbed_ } = useContext(WebParameterContext);
  const {_fieldList_} = useContext(DataContext)

  const [item, setItem] = useState("");
  const [sizeItem, setSizeItem] = useState("");
  const [xAxis, setXAxis] = useState([])
  const [yAxis, setYAxis] = useState([])
  const [chartData, setChartData] = useState([])
  // const xHasAgg = stateX.some(({agg})=>agg!=="")
  // const yHasAgg = stateY.some(({agg})=>agg!=="")
  const hasExcludeFieldX = stateX.some(({content}) => _fieldList_.includes(content))
  const hasExcludeFieldY = stateY.some(({content}) => _fieldList_.includes(content))
  const [xTypeNumber, setXTypeNumber] = useState(false)
  const [yTypeNumber, setYTypeNumber] = useState(false)

  useEffect(() => {
    const xAxisTemp = [];
    const yAxisTemp = [];
    stateX.forEach((item) => {
      if (item.agg !== "" && !item.content.includes(item.agg)) {
        xAxisTemp.push(`${item.agg}(${item.content})`);
      }
      if (item.agg === "") {
        xAxisTemp.push(item.content);
      }
    });
    stateY.forEach((item) => {
      if (item.agg !== "" && !item.content.includes(item.agg)) {
        yAxisTemp.push(`${item.agg}(${item.content})`);
      }
      if (item.agg === "") {
        yAxisTemp.push(item.content);
      }
    });
    setXTypeNumber(data.some(item=>typeof item[xAxisTemp[0]] === "number"))
    setYTypeNumber(data.some(item=>typeof item[yAxisTemp[0]] === "number"))
    let sortedData = data
    if(data.some(item=>typeof item[xAxisTemp[0]] === "number"))
      sortedData = _.orderBy(data, [xAxisTemp[0]], ["asc"]);
    //map data as string to plot as x axis value
    let newMapSortedData = sortedData.map(item=>{
      let newItem = {...item}
      //   newItem[xAxisTemp[0]] = String(newItem[xAxisTemp[0]])
      return newItem
    })
    const ds = new DataSet();
    const dv = ds.createView().source(newMapSortedData);
    //to assign attr that set to color or size
    let curr_name = "", curr_size_name = ""
    // const sampleData = data[0];
    //x1 y1 not set color
    if (xAxisTemp.length === 1 && yAxisTemp.length === 1) {
      setItem("")
      curr_name = yAxisTemp[0]
    }
    //for x or y > 1 and the rest axis has only 1
    //set color with attribute index 1
    if (
      (xAxisTemp.length > 1 || yAxisTemp.length > 1) &&
      (xAxisTemp.length === 1 || yAxisTemp.length === 1)
    ) {
      if (xAxisTemp.length > 1) {
        setItem(xAxisTemp[1]);
        curr_name = xAxisTemp[1]
      }
      if (yAxisTemp.length > 1 && xAxisTemp.length === 1) {
        setItem(yAxisTemp[1]);
        curr_name = yAxisTemp[1]
      }
    }
    //x,y axis more than 1
    //type number set as size
    //type string set as color
    if (xAxisTemp.length > 1 && yAxisTemp.length > 1) {
      if (
        data.some(item=> typeof item[xAxisTemp[1]] === "string") &&
        data.some(item=> typeof item[yAxisTemp[1]] === "number")
      ) {
        setItem(xAxisTemp[1]);
        setSizeItem(yAxisTemp[1]);
        curr_name = xAxisTemp[1]
        curr_size_name = yAxisTemp[1]
      } else if (
        data.some(item=> typeof item[xAxisTemp[1]] === "number") &&
        data.some(item=> typeof item[yAxisTemp[1]] === "string")
      ) {
        setItem(yAxisTemp[1]);
        setSizeItem(xAxisTemp[1]);
        curr_name = yAxisTemp[1]
        curr_size_name = xAxisTemp[1]
      }
      else if(data.some(item=> typeof item[xAxisTemp[1]] === "string") &&
      data.some(item=> typeof item[yAxisTemp[1]] === "string")){
        setItem(xAxisTemp[1]);
        curr_name = xAxisTemp[1]
      }
      else if(data.some(item=> typeof item[xAxisTemp[1]] === "number") &&
      data.some(item=> typeof item[yAxisTemp[1]] === "number")){
        setItem(xAxisTemp[1]);
        setSizeItem(yAxisTemp[1]);
        curr_name = xAxisTemp[1]
        curr_size_name = yAxisTemp[1]
      }
    }
    dv.transform({
      type: 'map',
      callback: (obj) => {
        //map data name and name2 for use this data with tooltip
        //name not only x but can be y which depend on attribute and data type
        //check type num format num
        let formatName = obj[curr_name] ?? 0
        let formatName2 = obj[curr_size_name] ?? 0
        if(data.some(item=> typeof item[curr_name] === "number"))
          formatName = new Intl.NumberFormat().format(obj[curr_name])
        if(data.some(item=> typeof item[curr_size_name] === "number"))
          formatName2 = new Intl.NumberFormat().format(obj[curr_size_name])
        //else for x1, y1 and name should be attribute name not obj value
        obj.name = xAxisTemp.length > 1 || yAxisTemp.length > 1 ? formatName : curr_name
        obj.name2 = curr_size_name
        obj.name2Value = formatName2
        return obj
      }
    });
    setChartData(dv.rows)
    setXAxis(xAxisTemp)
    setYAxis(yAxisTemp)
  }, [stateX, stateY, data]);
  const sliderFormatter = (val) =>{
    if((!isNaN(val) || val === "" || val === "null") && xTypeNumber && !hasExcludeFieldX) return getFormatNumber(val === "" || val === "null" ? 0 : +val)
    return val
  }
  const scale = {
    [xAxis[0]]: {
      nice: true,
    }
  }
  //set tickInterval or tickCount (choose one) for type Number only
  if(xTypeNumber){
    let max = _.maxBy(chartData, xAxis[0])[xAxis[0]]
    let tickCount = 5
    if(max >= 500 && max <= 5000){
      tickCount = 10
    }
    if(max > 5000 && max <= 50000){
      tickCount = 15
    }
    if(max > 50000 && max <= 500000){
      tickCount = 20
    }
    if(max > 500000 && max <= 10000000){
      tickCount = 50
    }
    if(max > 10000000){
      tickCount = 100
    }
    scale[[xAxis[0]]]["tickCount"] = tickCount
  }
  return (
    <Chart
      height="calc(100% - 25px)"
      width={"calc(100% - 20px)"}
      data={chartData}
      autoFit
      interactions={["legend-highlight"]}
      appendPadding={[15, 5, 10, 0]}
      scale={scale}
    >
      <Axis
        name={xAxis[0]}
        tickLine={{
          style: {
            lineWidth: 1,
            stroke: "#000",
          },
          length: 5,
        }}
        label={{
          formatter: val => `${(!isNaN(val) || val === "" || val === "null") && xTypeNumber && !hasExcludeFieldX ? getFormatNumber(val === "" || val === "null" ? 0 : +val) : val}`
        }}
      />
      <Axis
        name={yAxis[0]}
        label={{
          formatter: val => `${!isNaN(val) && yTypeNumber && !hasExcludeFieldY ? getFormatNumber(+val) : val}`
        }}
      />
      <Slider start={0} end={1} formatter={(val)=>sliderFormatter(val)}/>
      <Legend position="top" label={{
        formatter: val => `${!isNaN(val) ? getFormatNumber(+val) : val}`
      }}/>
      {sizeItem === "" ? (
        <>
          {/* <Legend position="top" /> */}
          <Point
            position={`${xAxis[0]}*${yAxis[0]}`}
            color={item}
            shape="circle"
            style={[
              item,
              (val) => {
                return {
                  lineWidth: 1,
                  strokeOpacity: 0.3,
                  fillOpacity: 0.6,
                  opacity: 0.65,
                  stroke: val,
                };
              },
            ]}
            tooltip={[`name*${yAxis[0]}`, (name, value) => {
              return {
                value: `${!isNaN(value) && yTypeNumber && !hasExcludeFieldY ? new Intl.NumberFormat().format(+value) : value}`,
                name
              }
            }]}
          />
        </>
      ) : (
        <>
          {/* <Legend name={sizeItem} position="top" /> */}
          <Point
            position={`${xAxis[0]}*${yAxis[0]}`}
            color={item}
            size={[sizeItem, [4, 60]]}
            shape="circle"
            style={[
              item,
              (val) => {
                return {
                  lineWidth: 1,
                  strokeOpacity: 0.3,
                  fillOpacity: 0.3,
                  opacity: 0.65,
                  stroke: val,
                };
              },
            ]}
            tooltip={[`name*${yAxis[0]}`, (name, value) => {
              return {
                value: `${!isNaN(value) && yTypeNumber && !hasExcludeFieldY ? new Intl.NumberFormat().format(+value) : value}`,
                name
              }
            }]}
          />
        </>
      )}
      <Tooltip>
        {(title,items) => {
          return <div>
                  {/* <div className="g2-tooltip-title">{xAxis[0]}:{(!isNaN(title) || title === "" || title === "null") && xTypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(title === "" || title === "null" ? 0 : +title) : title}</div>
                  {
                    (xAxis.length > 1 || yAxis.length > 1) &&
                  <div>
                    <span className="g2-tooltip-marker" style={{background: items[0].color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px'}}></span>
                      <span className="g2-tooltip-name">{items[0].name}</span>
                  </div>
                  } */}
                  <ul className="g2-tooltip-list" style={{textAlign: 'left'}}>
                    <li className="g2-tooltip-list-item">
                      <span className="g2-tooltip-name">{xAxis[0]}</span> :
                      <span className="g2-tooltip-value">{(!isNaN(title) || title === "" || title === "null") && xTypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(title === "" || title === "null" ? 0 : +title) : title}</span>
                    </li>
                    <li className="g2-tooltip-list-item">
                      {
                        (xAxis.length === 1 && yAxis.length === 1) &&
                        <span className="g2-tooltip-marker" style={{background: items[0].color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px'}}></span>
                      }
                      {/* <span className="g2-tooltip-name">{(!isNaN(items[0].name) || items[0].name === "" || items[0].name === "null") && xTypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(items[0].name === "" || items[0].name === "null" ? 0 : +items[0].name) : items[0].name}</span> : */}
                      <span className="g2-tooltip-name">{yAxis[0]}</span> :
                      <span className="g2-tooltip-value">{items[0].value}</span>
                    </li>
                    {
                    (xAxis.length > 1 || yAxis.length > 1) &&
                    <li className="g2-tooltip-list-item">
                      <span className="g2-tooltip-marker" style={{background: items[0].color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px'}}></span> :
                      <span className="g2-tooltip-value">{items[0].name}</span>
                    </li>
                    }
                    {
                    items[0].data.name2 &&
                    <li className="g2-tooltip-list-item">
                      <span className="g2-tooltip-name">{items[0].data.name2}</span> :
                      <span className="g2-tooltip-value">{items[0].data.name2Value}</span>
                    </li>
                    }
                  </ul>
                </div>
        }}
      </Tooltip>
    </Chart>
    // <Row>
    //   <Col span={23}>
    //     <Chart
    //       height={"calc(100vh - 220px)"}
    //       data={data}
    //       autoFit
    //       interactions={["legend-highlight"]}
    //     >
    //       <Legend name={sizeItem} visible />
    //       <Point
    //         position={`${xAxis[0]}*${yAxis[0]}`}
    //         color={item}
    //         size={[sizeItem, [4, 60]]}
    //         shape="circle"
    //         style={[
    //           item,
    //           (val) => {
    //             return {
    //               lineWidth: 1,
    //               strokeOpacity: 0.3,
    //               fillOpacity: 0.3,
    //               opacity: 0.65,
    //               stroke: val,
    //             };
    //           },
    //         ]}
    //       />
    //     </Chart>
    //   </Col>
    //   <Col span={1} style={{ height: 620 }}>
    //     <Menu
    //       style={{
    //         width: "100%",
    //         float: "right",
    //         transform: "rotateY(180deg)",
    //       }}
    //       mode="vertical"
    //       defaultSelectedKeys={["1"]}
    //     >
    //       <SubMenu key="sub1" icon={<SettingOutlined />}>
    //         <SubMenu key="sub2" title="Shape">
    //           <Menu.Item key="1">Normal</Menu.Item>
    //           <Menu.Item key="2">Ladder</Menu.Item>
    //         </SubMenu>
    //         <Menu.Item key="3">Option 9</Menu.Item>
    //       </SubMenu>
    //       <SubMenu key="sub3" icon={<MailOutlined />}>
    //         <Menu.Item key="4">Option 1</Menu.Item>
    //         <Menu.Item key="5">Option 2</Menu.Item>
    //       </SubMenu>
    //       <SubMenu key="sub4" icon={<AppstoreOutlined />}>
    //         <Menu.Item key="6">Option 5</Menu.Item>
    //         <Menu.Item key="7">Option 6</Menu.Item>
    //       </SubMenu>
    //     </Menu>
    //   </Col>
    // </Row>
  );
};

export default ScatterChart;
