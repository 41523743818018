import React, { useState, useContext } from "react"
import "./ShareSetting.css";
import { Tooltip, Button} from "antd";
import { FileTextFilled, SettingFilled, EditFilled, FullscreenOutlined } from "@ant-design/icons";
import { WebParameterContext } from "../../contexts/webParameter";
import { getFilterStr, getSelectedAnotherChartAxis, getSelectedHeatmapChartAxis, getEditLabel, getHeatmapMapLevel } from "../../helpers";
const ShareSetting = ({dataInfo, updateFullscreenMode, copySharableLink}) => {
    const {
        _chartFilter_,
        _currBaseMap_,
        _chart_,
        _lineSort_,
        _barSort_,
        _location_,
        _scoreCardLabel_,
        _mapFilter_,
        _tooltipField_,
        _limitOffsetMap_,
        _chartHeatmapFilter_,
        _mapLevel_,
        _barChartType_,
        _utmLocation_,
        _cardColor_,
        _selectedLbState_,
        _selectedPieState_,
        _selectedBarState_,
        _selectedScatterTreemapState_,
        _selectedScorecardState_,
        _selectedSunburstState_,
        _selectedHeatmapState_,
        _selectedLbStateFromShare_,
        _selectedPieStateFromShare_,
        _selectedBarStateFromShare_,
        _selectedScatterTreemapStateFromShare_,
        _selectedScorecardStateFromShare_,
        _selectedSunburstStateFromShare_,
        _selectedHeatmapStateFromShare_,
        _currentMapLayer_
    } = useContext(WebParameterContext);
    const [show, setShow] = useState(false)
    // const [isFullscreen, setIsFullscreen] = useState(false);
    const settingShow = () =>{
        setShow(!show)
    }
    /* const onChangeFullscreenMode = () => {
        setIsFullscreen(!isFullscreen);
        updateFullscreenMode(!isFullscreen)
    }; */
    const openSharedLink = ()=>{
        let sharedUrl = window.location.origin + window.location.pathname;
        const chartFilterStr = getFilterStr(_chartFilter_, "chart");
        const mapFilterStr = getFilterStr(_mapFilter_, "map");
        const heatmapFilter =  _chart_ === "Heatmap" ? getFilterStr(_chartHeatmapFilter_, "heatmap") : "";

        const lineSort = _chart_ === "Line chart" ? `&ls=${_lineSort_}` : "";
        const barSort = _chart_ === "Bar chart" || _chart_ === "Bar chart2" ? `&bs=${_barSort_}` : "";
        const barType = _chart_ === "Bar chart" || _chart_ === "Bar chart2" ? `&bt=${_barChartType_}` : "";

        const lat = _location_.lat !== "" ? `&lat=${encodeURIComponent(_location_.lat)}` : "";
        const lng = _location_.lng !== "" ? `&lng=${encodeURIComponent(_location_.lng)}` : "";
        const utm = _utmLocation_.x !== "" && _utmLocation_.y !== "" && _utmLocation_.zone !== "" ? `&utm=${encodeURIComponent(JSON.stringify(_utmLocation_))}` : "";
        let selectedLbStr = _chart_ === "Line chart" || _chart_ === "Bar chart" ? getSelectedAnotherChartAxis(_selectedLbState_, "linebar") : "";

        // let selectedPieBarStr = _chart_ === "Pie chart" || _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedPieBarState_, "pie") : "";
        let selectedPieStr = _chart_ === "Pie chart" ? getSelectedAnotherChartAxis(_selectedPieState_, "pie") : "";
        let selectedBarStr = _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedBarState_, "bar") : "";
        let selectedScatterTreemapStr = _chart_ === "Scatter chart" || _chart_ === "Treemap chart" ? getSelectedAnotherChartAxis(_selectedScatterTreemapState_, _chart_ === "Scatter chart" ? "scatter" : "treemap") : "";
        let selectedScorecardStr = _chart_ === "Scorecard" ? getSelectedAnotherChartAxis(_selectedScorecardState_, "scorecard") : "";
        let selectedSunburstStr = _chart_ === "Sunburst chart" ? getSelectedAnotherChartAxis(_selectedSunburstState_, "sunburst") : "";
        let selectedHeatmapStr = _chart_ === "Heatmap" ? getSelectedHeatmapChartAxis(_selectedHeatmapState_) : ""
        const mapLevelStr = _chart_ === "Heatmap" ? getHeatmapMapLevel(_mapLevel_) : ""
        let scoreCardLabel = "", color = "";
        if(_chart_ === "Scorecard"){
            scoreCardLabel = getEditLabel(_scoreCardLabel_)
            color = `&color=${JSON.stringify([_cardColor_.background, _cardColor_.title, _cardColor_.number])}`
        }
        const searchParams = new URLSearchParams(window.location.search);
        //also handle when share from shared link
        if(searchParams.has("linebar")) {
            selectedLbStr = _chart_ === "Line chart" || _chart_ === "Bar chart" ? getSelectedAnotherChartAxis(_selectedLbStateFromShare_, "linebar") : "";
        }
        if(searchParams.has("pie")) {
            selectedPieStr = _chart_ === "Pie chart" ? getSelectedAnotherChartAxis(_selectedPieStateFromShare_, "pie") : "";
        }
        if(searchParams.has("bar")) {
            selectedBarStr = _chart_ === "Bar chart2" ? getSelectedAnotherChartAxis(_selectedBarStateFromShare_, "bar") : "";
        }
    
        if(searchParams.has("scatter") || searchParams.has("treemap")) {
            selectedScatterTreemapStr = _chart_ === "Scatter chart" || _chart_ === "Treemap chart" ? getSelectedAnotherChartAxis(_selectedScatterTreemapStateFromShare_, _chart_ === "Scatter chart" ? "scatter" : "treemap") : "";
        }
        if(searchParams.has("scorecard")) {
            selectedScorecardStr = _chart_ === "Scorecard" ? getSelectedAnotherChartAxis(_selectedScorecardStateFromShare_, "scorecard") : "";
        }
        if(searchParams.has("sunburst")) {
            selectedSunburstStr = _chart_ === "Sunburst chart" ? getSelectedAnotherChartAxis(_selectedSunburstStateFromShare_, "sunburst") : "";
        }
        if(searchParams.has("heatmap")) {
            selectedHeatmapStr = _chart_ === "Heatmap" ? getSelectedHeatmapChartAxis(_selectedHeatmapStateFromShare_) : "";
        }
        if(searchParams.has("map")){
            const mapBase = `?map&base=${_currBaseMap_}`;
            sharedUrl += `${mapBase}${lat}${lng}${utm}${mapFilterStr}${_tooltipField_.length > 0 ? `&field=${encodeURIComponent(JSON.stringify(_tooltipField_))}` : ""}&mlimit=${_limitOffsetMap_.limit}&moffset=${_limitOffsetMap_.offset}&layer=${encodeURIComponent(_currentMapLayer_)}`;
        }
        if(searchParams.has("chart")){
            sharedUrl += `?chart&charttype=${_chart_}${lineSort}${barSort}${barType}${chartFilterStr}${heatmapFilter}${selectedLbStr}${selectedPieStr}${selectedBarStr}${selectedScatterTreemapStr}${selectedScorecardStr}${selectedHeatmapStr}${selectedSunburstStr}${scoreCardLabel?scoreCardLabel:""}${color}${mapLevelStr}`;
        }
        sharedUrl = encodeURI(sharedUrl);
        window.open(sharedUrl, "_blank")
    }
    return (
        <div className="setting-layout">
            {
            show &&
            <div className="setting-sub-menu">
                <a href={dataInfo.value.ref_url} target="_blank" rel="noopener noreferrer" style={{display: 'flex', alignItems: 'center'}}>
                    <Tooltip placement="top" title="กลับไปชุดข้อมูล">
                        <FileTextFilled className="setting-icon" />
                    </Tooltip>
                </a>
                <Tooltip placement="top" title="กลับไปยังหน้า playground">
                    <EditFilled className="setting-icon" onClick={()=>copySharableLink("edit")}/>
                </Tooltip>
                <Tooltip placement="top" title="ขยาย">
                    <Button
                        icon={<FullscreenOutlined className="full-screen-icon" />}
                        shape="circle"
                        className="full-screen-chart full-screen-chart-button"
                        size="small"
                        onClick={openSharedLink}
                    />
                </Tooltip>
                {/* {
                isFullscreen ?
                <Tooltip placement="top" title="ยกเลิกขยาย">
                    <Button
                        icon={<FullscreenExitOutlined className="full-screen-icon" />}
                        shape="circle"
                        className="full-screen-chart exit-full-screen-button"
                        size="small"
                        onClick={onChangeFullscreenMode}
                    />
                </Tooltip>
                :
                <Tooltip placement="top" title="ขยาย">
                    <Button
                        icon={<FullscreenOutlined className="full-screen-icon" />}
                        shape="circle"
                        className="full-screen-chart full-screen-chart-button"
                        size="small"
                        onClick={onChangeFullscreenMode}
                    />
                </Tooltip>
                } */}
            </div>
            }
            <div style={{padding: '7px 0', display: 'flex', alignItems: 'center'}}>
                <SettingFilled className="setting-icon" onClick={settingShow}/>
            </div>
        </div>
    )
}
export default ShareSetting