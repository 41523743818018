import React, { useState, useContext } from "react";
import "./ChartButtonMenu.css";
import { Radio, Tooltip } from "antd";
import Icon, {
  LineChartOutlined,
  BarChartOutlined,
  PieChartOutlined,
  DotChartOutlined,
  BlockOutlined,
  AppstoreOutlined,
  GlobalOutlined
} from "@ant-design/icons";

import { WebParameterContext } from "../../contexts/webParameter";
const Bar2Svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 46 46"><path fill="currentColor" fillRule="evenodd" d="M12 24a1 1 0 0 0-1 1v15H8V7H6v34a1 1 0 0 0 1 1h34v-2h-2V13a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v27h-2V21a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v19h-2V25a1 1 0 0 0-1-1h-6Zm21 16h4V26h-4v14Zm4-16h-4V14h4v10Zm-14 6v10h4V30h-4Zm4-2h-4v-6h4v6Zm-14 6v6h4v-6h-4Zm4-2h-4v-6h4v6Z" clipRule="evenodd" strokeWidth={1} stroke="currentColor"/></svg>
)
const SunburstSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 30 30"><path fill="currentColor" d="M16 2a1 1 0 0 0-1 1v7.09a5.962 5.962 0 0 0-2.46 1.043L7.838 6.431a1.455 1.455 0 0 0-2.087.024a14.05 14.05 0 0 0 4.054 22.142a10.848 10.848 0 0 0 1.899.768a14.098 14.098 0 0 0 13.844-3.132a1.434 1.434 0 0 0 .028-2.064l-4.699-4.699A5.963 5.963 0 0 0 21.91 17H29a1 1 0 0 0 1-1A14.016 14.016 0 0 0 16 2Zm0 10a4 4 0 1 1-4 4a4.005 4.005 0 0 1 4-4ZM6.83 8.251l4.296 4.296a5.91 5.91 0 0 0-.011 6.924l-4.277 4.277A12.017 12.017 0 0 1 6.83 8.251Zm1.423 16.91l4.276-4.276A5.959 5.959 0 0 0 15 21.91v6.042a11.878 11.878 0 0 1-6.747-2.79ZM17 27.956V21.91a5.963 5.963 0 0 0 2.46-1.027l4.283 4.282A11.89 11.89 0 0 1 17 27.956ZM21.91 15A6.006 6.006 0 0 0 17 10.09V4.041A12.02 12.02 0 0 1 27.959 15Z"/></svg>
)
const Bar2Icon = (props) => <Icon component={Bar2Svg} {...props} />;
const SunburstIcon = (props) => <Icon component={SunburstSvg} {...props} />;
const ChartButtonMenu = ({ onChangeChart, isDisabled }) => {
  const {
    _chartType_
  } = useContext(WebParameterContext);

  const [value, setValue] = useState(_chartType_ || "Line chart");

  const onChangeValue = (e) => {
    setValue(e.target.value);
    onChangeChart(e.target.value);
  };

  const ButtonMenu = chartList.map((chart) => {
    return (
      <Tooltip key={chart.type} title={chart.text}>
        <Radio.Button className="custom-button" value={chart.type} style={{ height: 54, width: 54 }} disabled={isDisabled}>
          {chart.icon}
        </Radio.Button>
      </Tooltip>
    );
  });

  return (
    <Radio.Group
      value={value}
      onChange={onChangeValue}
      style={{ height: 54 }}
      buttonStyle="solid"
    >
      {ButtonMenu}
    </Radio.Group>
  );
};

export default ChartButtonMenu;

const chartList = [
  {
    type: "Line chart",
    text: "Line chart",
    th_text: "แผนภูมิเส้น",
    icon: <LineChartOutlined className="chart-button-menu-icon" />,
    sub_type: [],
  },
  {
    type: "Bar chart",
    text: "Bar chart(X: 1,Y: N)",
    th_text: "แผนภูมิแท่ง",
    icon: <BarChartOutlined className="chart-button-menu-icon" />,
    sub_type: [],
  },
  {
    type: "Bar chart2",
    text: "Bar chart(X: 2, Y: 1)",
    th_text: "แผนภูมิแท่ง",
    icon: <Bar2Icon className="chart-button-menu-icon" />,
    sub_type: [],
  },
  {
    type: "Pie chart",
    text: "Pie chart",
    th_text: "แผนภูมิวงกลม",
    icon: <PieChartOutlined className="chart-button-menu-icon" />,
    sub_type: [],
  },
  {
    type: "Sunburst chart",
    text: "Sunburst chart",
    th_text: "Sunburst",
    icon: <SunburstIcon className="chart-button-menu-icon" />,
    sub_type: [],
  },
  {
    type: "Scatter chart",
    text: "Scatter chart",
    th_text: "แผนภูมิกระจาย",
    icon: <DotChartOutlined className="chart-button-menu-icon" />,
    sub_type: [],
  },
  {
    type: "Treemap chart",
    text: "Treemap chart",
    th_text: "แผนภูมิทรีแมป",
    icon: <BlockOutlined className="chart-button-menu-icon" />,
    sub_type: [],
  },
  {
    type: "Scorecard",
    text: "Scorecard",
    th_text: "Scorecard",
    icon: <AppstoreOutlined className="chart-button-menu-icon" />,
    sub_type: [],
  },
  {
    type: "Heatmap",
    text: "Heatmap",
    th_text: "Heatmap",
    icon: <GlobalOutlined className="chart-button-menu-icon" />,
    sub_type: [],
  },
];
