import React, { useContext, useEffect, useState } from "react";
import "./LineChart.css";
import { Chart, Line, Point, Tooltip, Slider, Legend, Axis } from "bizcharts";
import { WebParameterContext } from "../../../contexts/webParameter";

import ChartOrderBy from "../../ChartOrderBy";
import { getFormatNumber } from "../../../utils/formatNumber";
import DataSet from "@antv/data-set";
import { DataContext } from "../../../contexts/data";
import _ from "lodash"

/*const titleX = {
  style: {
    fontSize: 16,
    textAlign: "center",
    fill: "#777",
    fontWeight: "bold",
  },
  offset: 100,
};
 const titleY = {
  style: {
    fontSize: 16,
    textAlign: "center",
    fill: "#777",
    fontWeight: "bold",
  },
}; */

const LineChart = ({ data, stateX, stateY }) => {
  const { _embed_, _chartInfoEmbed_ } = useContext(WebParameterContext);
  const { _fieldList_ } = useContext(DataContext);
  const [d, setD] = useState([]);
  const [initData, setInitData] = useState([]);
  const [cache, setCache] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  const [xAxis, setXAxis] = useState([]);
  // const xHasAgg = stateX.some(({agg})=>agg!=="")
  // const yHasAgg = stateY.some(({agg})=>agg!=="")
  const hasExcludeFieldX = stateX.some(({content}) => _fieldList_.includes(content))
  const hasExcludeFieldY = stateY.some(({content}) => _fieldList_.includes(content))
  // const xTypeNumber = _schema_.value[stateX[0].content] === "number"
  // const yTypeNumber = stateY.some(({type})=>type === "number")
  const [xTypeNumber, setXTypeNumber] = useState(false)
  const [yTypeNumber, setYTypeNumber] = useState(false)
  useEffect(()=>{
    const xAxisTemp = [];
    const yAxisTemp = [];
    stateX.forEach((item) => {
      if (item.agg !== "" && !item.content.includes(item.agg)) {
        xAxisTemp.push(`${item.agg}(${item.content})`);
      }
      if (item.agg === "") {
        xAxisTemp.push(item.content);
      }
    });
    setXAxis(xAxisTemp)
    stateY.forEach((item) => {
      if (item.agg !== "" && !item.content.includes(item.agg)) {
        yAxisTemp.push(`${item.agg}(${item.content})`);
      }
      if (item.agg === "") {
        yAxisTemp.push(item.content);
      }
    });
    setYAxis(yAxisTemp)
    setXTypeNumber(data.some(item=>typeof item[xAxisTemp[0]] === "number"))
    let sortedData = data
    if(data.some(item=>typeof item[xAxisTemp[0]] === "number"))
      sortedData = _.orderBy(data, [xAxisTemp[0]], ["asc"]);

    const ds = new DataSet();
    const dv = ds.createView().source(sortedData);
    dv.transform({
      type: 'rename',
      map:{
        [xAxisTemp[0]]: 'x' //rename x axis key as x(new key)
      }
    }).transform({
      type: "fold",
      fields: yAxisTemp,
      key: "name",// key name represent fields
      value: "y",// key value of each field value
      retains: ['x'] // x axis key
    }).transform({//map x value to string and y value isNaN
      type: 'map',
      callback: (obj) => {
        obj.x = String(obj.x)
        obj.y = typeof obj.y === "number" || obj.y === "" || obj.y === null ? Number(obj.y) : obj.y
        return obj
      }
    });

    setYTypeNumber(dv.rows.some(({y}) => typeof y === "number" && y !== 0))

    if(yAxisTemp.length === 1){
      setInitData(dv.rows)
    }
    else{
      setD(dv.rows)
    }
    setCache(dv.rows)
  },[data, stateX, stateY])
  const updateData = (_d) => {
    setD(_d);
  };
  // const position = `${xAxis[0]}*${yAxis[0]}`;
  const scale = {
    x: {
      range: [0, 1],
      alias: "",
    },
    y: {
      alias: "",
      min: 0,
      nice: true,
    },
  };

 /*  let color = yAxis.length > 1 ? yAxis[1] : [yAxis[0], "#5B8FF9"];
  color = xAxis.length > 1 ? xAxis[1] : "#5B8FF9"; */
  const sliderFormatter = (val) =>{
    if((!isNaN(val) || val ==="" || val === "null") && xTypeNumber && !hasExcludeFieldX) return getFormatNumber(val ==="" || val === "null" ? 0 : +val)
    return val
  }

  return (
    <>
      <div style={{ textAlign: "start", marginBottom: 5 }}>
        {(yAxis.length === 1 && initData.length > 0) &&
        <ChartOrderBy
          parent="Line chart"
          updateData={updateData}
          data={initData}
          axis="y"
          cache={cache}
        />
      }
      </div>
      <Chart
        autoFit
        height={
          _embed_
            ? _chartInfoEmbed_
              ? "calc(100vh - 262px)"
              : "calc(100vh - 132px)"
            : "calc(100vh - 262px)"
        }
        width={"calc(100% - 20px)"}
        data={d}
        scale={scale}
        appendPadding={[15, 5, 10, 0]}
      >
        <Axis
          name="x"
          tickLine={{
            style: {
              lineWidth: 1,
              stroke: "#000",
            },
            length: 5,
          }}
          label={{
            formatter: val => `${(!isNaN(val) || val ==="" || val === "null") && xTypeNumber && !hasExcludeFieldX ? getFormatNumber(val ==="" || val === "null" ? 0 : +val) : val}`
          }}
        />
        <Axis
          name="y"
          label={{
            formatter: val => `${!isNaN(val) && yTypeNumber && !hasExcludeFieldY ? getFormatNumber(+val) : val}`
          }}
        />
        <Legend position="top-left" />
        <Line position="x*y" size={2} color="name" tooltip={['name*y', (name, value) => {
          return {
            value: `${!isNaN(value) && yTypeNumber && !hasExcludeFieldY ? new Intl.NumberFormat().format(+value) : value}`,
            name
          }
        }]} />
        <Point position="x*y" size={4} color="name" style={{lineWidth: 1}} tooltip={['name*y', (name, value) => {
          return {
            value: `${!isNaN(value) && yTypeNumber && !hasExcludeFieldY ? new Intl.NumberFormat().format(+value) : value}`,
            name
          }
        }]}/>
        <Tooltip showCrosshairs >
          {(title,items) => {
            return <div>
                      {/* <div className="g2-tooltip-title">{(!isNaN(title) || title ==="" || title === "null") && xTypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(title ==="" || title === "null" ? 0 : +title) : title}</div> */}
                      <ul className="g2-tooltip-list" style={{textAlign: 'left'}}>
                        <li className="g2-tooltip-list-item">
                          <span className="g2-tooltip-name">{xAxis[0]}</span> :
                          <span className="g2-tooltip-value">{(!isNaN(title) || title === "" || title === "null") && xTypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(title === "" || title === "null" ? 0 : +title) : title}</span>
                        </li>
                        <li className="g2-tooltip-list-item">
                          <span className="g2-tooltip-marker" style={{background: items[0].color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px'}}></span>
                          <span className="g2-tooltip-name">{items[0].name}</span> :
                          <span className="g2-tooltip-value">{items[0].value}</span>
                        </li>
                      </ul>
                    </div>
          }}
        </Tooltip>
        <Slider start={0} end={1} formatter={(val)=>sliderFormatter(val)}/>
      </Chart>
    </>
  );
};

export default LineChart;
