import React, { useState, useEffect, useContext } from "react";
import "./BarChart.css";
import {
  Chart,
  Coordinate,
  Interval,
  Tooltip,
  Legend,
  Slider,
  Axis,
} from "bizcharts";
import { WebParameterContext } from "../../../contexts/webParameter";
import ChartOrderBy from "../../ChartOrderBy";
import BarChartType from "../../BarChartType";
import { getFormatNumber } from "../../../utils/formatNumber";
import DataSet from "@antv/data-set";
import { DataContext } from "../../../contexts/data";
import _ from "lodash"

// const titleX = {
//   style: {
//     fontSize: 16,
//     textAlign: "center",
//     fill: "#777",
//     fontWeight: "bold",
//   },
//   offset: 100,
// };
// const titleY = {
//   style: {
//     fontSize: 16,
//     textAlign: "center",
//     fill: "#777",
//     fontWeight: "bold",
//   },
// };

const BarChart2 = ({ data, stateX, stateY }) => {
  const { _embed_, _chartInfoEmbed_ } = useContext(WebParameterContext);
  const { _fieldList_ } = useContext(DataContext);
  const [defaultData, setDefaultData] = useState([]);
  const [d, setD] = useState([]);
  const [type, setType] = useState("STACK");
  // const [transpose, setTranspose] = useState(false);
  const transpose = false
  // const [position, setPosition] = useState(`${xAxis[0]}*${yAxis[0]}`);
  // const [color, setColor] = useState(
  //   yAxis.length > 1 ? yAxis[1] : [yAxis[0], "#5B8FF9"]
  // );
  const [position, setPosition] = useState();
  // const xHasAgg = stateX.some(({agg})=>agg!=="")
  // const yHasAgg = stateY.some(({agg})=>agg!=="")
  const hasExcludeFieldX = stateX.some(({content}) => _fieldList_.includes(content))
  const hasExcludeFieldY = stateY.some(({content}) => _fieldList_.includes(content))

  const [x0TypeNumber, setX0TypeNumber] = useState(false)
  const [x1TypeNumber, setX1TypeNumber] = useState(false)
  const [yTypeNumber, setYTypeNumber] = useState(false)
  const [xAxis, setXAxis] = useState([]);

  const updateData = (_d) => {
    setD(_d);
  };

  const updateType = (value) => {
    if (value === "STACK") {
      setType("STACK");
    } else {
      setType("DODGE");
    }
  };

  useEffect(() => {
    const xAxisTemp = [];
    const yAxisTemp = [];
    stateX.forEach((item) => {
      if (item.agg !== "" && !item.content.includes(item.agg)) {
        xAxisTemp.push(`${item.agg}(${item.content})`);
      }
      if (item.agg === "") {
        xAxisTemp.push(item.content);
      }
    });
    setXAxis(xAxisTemp)
    stateY.forEach((item) => {
      if (item.agg !== "" && !item.content.includes(item.agg)) {
        yAxisTemp.push(`${item.agg}(${item.content})`);
      }
      if (item.agg === "") {
        yAxisTemp.push(item.content);
      }
    });
    setX0TypeNumber(data.some(item=>typeof item[xAxisTemp[0]] === "number"))
    let sortedData = data
    if(data.some(item=>typeof item[xAxisTemp[0]] === "number"))
      sortedData = _.orderBy(data, [xAxisTemp[0]], ["asc"]);
    const ds = new DataSet();
    const dv = ds.createView().source(sortedData);
    dv.transform({
      type: 'rename',
      map:{
        [xAxisTemp[0]]: 'x', //rename x axis key as x(new key)
        [xAxisTemp[1]]: 'name',
        [yAxisTemp[0]]: 'y'
      }
    })
    .transform({//map x value to string and y value isNaN
      type: 'map',
      callback: (obj) => {
        obj.x = String(obj.x)
        obj.y = obj.y && typeof obj.y === "number" ? Number(obj.y) : obj.y
        obj.name = xAxisTemp[1] ? obj.name : yAxisTemp[0]
        obj.nameYAxis = yAxisTemp[0]
        return obj
      }
    });
    setYTypeNumber(dv.rows.some(({y}) => typeof y === "number"))
    setX1TypeNumber(dv.rows.some(({name}) => typeof name === "number"))
    setDefaultData(dv.rows)
    setPosition("x*y")
    // eslint-disable-next-line
  }, [data, stateX, stateY]);
 
  const sliderFormatter = (val) =>{
    if((!isNaN(val) || val === "" || val === "null") && x0TypeNumber && !hasExcludeFieldX) return getFormatNumber(val === "" || val === "null" ? 0 : +val)
    return val
  }
  return (
    <div>
      <div style={{ textAlign: "start", marginBottom: 5 }}>
        {
          defaultData.length > 0 &&
          <>
            <ChartOrderBy
              parent="Bar chart"
              updateData={updateData}
              data={defaultData}
              axis={transpose ? 'x' : 'y'}
            />
            <BarChartType updateType={updateType} />
          </>
        }
      </div>
      {type === "STACK" ? (
        <Chart
          height={
            _embed_
              ? _chartInfoEmbed_
                ? "calc(100vh - 262px)"
                : "calc(100vh - 132px)"
              : "calc(100vh - 262px)"
          }
          width={"calc(100% - 20px)"}
          data={d}
          autoFit
          appendPadding={[15, 5, 10, 0]}
        >
          <Axis
            name="x"
            tickLine={{
              style: {
                lineWidth: 1,
                stroke: "#000",
              },
              length: 5,
            }}
            label={{
              formatter: val => `${(!isNaN(val) || val === "" || val === "null") && x0TypeNumber && !hasExcludeFieldX ? getFormatNumber(val === "" || val === "null" ? 0 : +val) : val}`
            }}
          />
          <Axis
            name="y"
            label={{
              formatter: val => `${!isNaN(val) && yTypeNumber && !hasExcludeFieldY ? getFormatNumber(+val) : val}`
            }}
          />
          <Legend
            position="top-left"
            label={{
              formatter: val => `${!isNaN(val) ? getFormatNumber(+val) : val}`
            }}
          />
          <Coordinate transpose={transpose} />
          <Tooltip shared>
            {(title,items) => {
              return <div>
                      {/* <div className="g2-tooltip-title">{(!isNaN(title) || title === "" || title === "null") && x0TypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(title === "" || title === "null" ? 0 : +title) : title}</div> */}
                      <ul className="g2-tooltip-list" style={{textAlign: 'left'}}>
                        <li className="g2-tooltip-list-item">
                          <span className="g2-tooltip-name">{xAxis[0]}</span> :
                          <span className="g2-tooltip-value">{(!isNaN(title) || title === "" || title === "null") && x0TypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(title === "" || title === "null" ? 0 : +title) : title}</span>
                        </li>
                        {
                          items.map((item, index)=>(
                            <li className="g2-tooltip-list-item" key={index}>
                              <span className="g2-tooltip-marker" style={{background: item.color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px'}}></span>
                              <span className="g2-tooltip-name">{item.name}</span> :
                              <span className="g2-tooltip-value">{item.value}</span>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
            }}
          </Tooltip>
          <Interval
            position={position}
            color="name"
            adjust={[{ type: "stack" }]}
            tooltip={[`name*y`, (name, value) => {
              return {
                value: `${!isNaN(value) && yTypeNumber && !hasExcludeFieldY ? new Intl.NumberFormat().format(+value) : value}`,
                name: !isNaN(name) && x1TypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(+name) : name
              }
            }]}
          />
          <Slider start={0} end={1} formatter={(val)=>sliderFormatter(val)} />
        </Chart>
      ) : (
        <div>
          <Chart
            height={
              _embed_
                ? _chartInfoEmbed_
                  ? "calc(100vh - 262px)"
                  : "calc(100vh - 132px)"
                : "calc(100vh - 262px)"
            }
            width={"calc(100% - 20px)"}
            data={d}
            autoFit
            appendPadding={[15, 5, 10, 0]}
          >
            <Axis
              name="x"
              tickLine={{
                style: {
                  lineWidth: 1,
                  stroke: "#000",
                },
                length: 5,
              }}
              label={{
                formatter: val => `${(!isNaN(val) || val === "" || val === "null") && x0TypeNumber && !hasExcludeFieldX ? getFormatNumber(val === "" || val === "null" ? 0 : +val) : val}`
              }}
            />
            <Axis
              name="y"
              label={{
                formatter: val => `${!isNaN(val) && yTypeNumber && !hasExcludeFieldY ? getFormatNumber(+val) : val}`
              }}
            />
            <Legend position="top-left" label={{
              formatter: val => `${!isNaN(val) ? getFormatNumber(+val) : val}`
            }}/>
            <Coordinate transpose={transpose} />
            <Tooltip>
              {(title,items) => {
                return <div>
                        {/* <div className="g2-tooltip-title">{(!isNaN(title) || title === "" || title === "null") && x0TypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(title === "" || title === "null" ? 0 : +title) : title}</div> */}
                        <ul className="g2-tooltip-list" style={{textAlign: 'left'}}>
                          <li className="g2-tooltip-list-item">
                            <span className="g2-tooltip-name">{xAxis[0]}</span> :
                            <span className="g2-tooltip-value">{(!isNaN(title) || title === "" || title === "null") && x0TypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(title === "" || title === "null" ? 0 : +title) : title}</span>
                          </li>
                          <li className="g2-tooltip-list-item">
                            <span className="g2-tooltip-marker" style={{background: items[0].color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px'}}></span>
                            <span className="g2-tooltip-name">{items[0].name}</span> :
                            <span className="g2-tooltip-value">{items[0].value}</span>
                          </li>
                        </ul>
                      </div>
              }}
            </Tooltip>
            <Interval
              position={position}
              color="name"
              adjust={[
                {
                  type: "dodge",
                  marginRatio: 1 / 32,
                },
              ]}
              tooltip={[`name*y`, (name, value) => {
                return {
                  value: `${!isNaN(value) && yTypeNumber && !hasExcludeFieldY ? new Intl.NumberFormat().format(+value) : value}`,
                  name: !isNaN(name) && x1TypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(+name) : name
                }
              }]}
            />
            <Slider start={0} end={1} formatter={(val)=>sliderFormatter(val)}/>
          </Chart>
        </div>
      )}
    </div>
  );
};

export default BarChart2;
