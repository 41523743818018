import React, { useState } from "react";

export const WebParameterContext = React.createContext({});
const WebParameterProvider = (props) => {
  const [_baseUrl_, _setBaseUrl_] = useState({ value: "", isUpdated: false });
  const [_page_, _setPage_] = useState(1);
  const [_pageSize_, _setPageSize_] = useState(10);
  const [_filter_, _setFilter_] = useState("");
  const [_isCluster_, _setIsCluster_] = useState(false);
  const [_embed_, _setEmbed_] = useState(false);
  const [_chartInfoEmbed_, _setChartInfoEmbed_] = useState(false);
  const [_chartType_, _setChartType_] = useState("");
  const [_chart_, _setChart_] = useState("Line chart");
  const [_chartFilter_, _setChartFilter_] = useState([]);
  const [_mainFilter_, _setMainFilter_] = useState([]);
  const [_mapFilter_, _setMapFilter_] = useState([]);
  const [_chartHeatmapFilter_, _setChartHeatmapFilter_] = useState([]);
  const [_mapFilterStr_, _setMapFilterStr_] = useState("");
  //not used
  const [_selectedState_, _setSelectedState_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
    selectedYRepeat: [],
    itemsAStatic: [],
    selectedYHeatmap: [],
    itemsAHeatmap: []
  });
  //not used
  const [_selectedStateFromShare_, _setSelectedStateFromShare_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
    selectedYRepeat: [],
    itemsAStatic: [],
    selectedYHeatmap: [],
    itemsAHeatmap: []
  });
  const [_selectedLbState_, _setSelectedLbState_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  // const [_selectedPieBarState_, _setSelectedPieBarState_] = useState({
  //   itemsA: [],
  //   selectedX: [],
  //   selectedY: [],
  // })
  const [_selectedPieState_, _setSelectedPieState_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [_selectedBarState_, _setSelectedBarState_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [_selectedSunburstState_, _setSelectedSunburstState_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [_selectedScatterTreemapState_, _setSelectedScatterTreemapState_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [_selectedScorecardState_, _setSelectedScorecardState_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [_selectedHeatmapState_, _setSelectedHeatmapState_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [_selectedLbStateFromShare_, _setSelectedLbStateFromShare_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  // const [_selectedPieBarStateFromShare_, _setSelectedPieBarStateFromShare_] = useState({
  //   itemsA: [],
  //   selectedX: [],
  //   selectedY: [],
  // })
  const [_selectedPieStateFromShare_, _setSelectedPieStateFromShare_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [_selectedBarStateFromShare_, _setSelectedBarStateFromShare_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [_selectedSunburstStateFromShare_, _setSelectedSunburstStateFromShare_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [_selectedScatterTreemapStateFromShare_, _setSelectedScatterTreemapStateFromShare_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [_selectedScorecardStateFromShare_, _setSelectedScorecardStateFromShare_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [_selectedHeatmapStateFromShare_, _setSelectedHeatmapStateFromShare_] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [_currBaseMap_, _setCurrBaseMap_] = useState("streets-v11");
  const [_lineSort_, _setLineSort_] = useState("DEFAULT");
  const [_barSort_, _setBarSort_] = useState("DEFAULT");
  const [_barChartType_, _setBarChartType_] = useState("STACK");
  const [_location_, _setLocation_] = useState({ lat: "", lng: "" });
  const [_scoreCardLabel_, _setScorecardLabel_] = useState([])
  const [_sorter_, _setSorter_] = useState("");
  const [_tableSorter_, _setTableSorter_] = useState([]);
  const [_tooltipField_, _setTooltipField_] = useState([])
  const [_limitOffsetMap_, _setLimitOffsetMap_] = useState({
    offset: 0,
    limit: 1000
  })
  const [_region_, _setRegion_] = useState("")
  const [_province_, _setProvince_] = useState("")
  const [_district_, _setDistrict_] = useState("")
  const [_subDistrict_, _setSubDistrict_] = useState("")
  const [_provinceList_, _setProvinceList_] = useState([])
  const [_districtList_, _setDistrictList_] = useState([])
  const [_subDistrictList_, _setSubDistrictList_] = useState([])
  const [_zoomLevel_, _setZoomLevel_] = useState("")
  const [_coordinate_, _setCoordinate_] = useState(false)
  const [_latitude_, _setLatitude_] = useState("")
  const [_longitude_, _setLongitude_] = useState("")
  const [_showBack_, _setShowBack_] = useState(true)
  const [_showEdit_, _setShowEdit_] = useState(true)
  const [_showZoom_, _setShowZoom_] = useState(true)
  const [_showType_, _setShowType_] = useState(true)
  const [_showInfo_, _setShowInfo_] = useState(true)
  const [_titleFont_, _setTitleFont_] = useState(false)
  const [_showFilter_, _setShowFilter_] = useState(true)
  const [_sharedTitle_, _setSharedTitle_] = useState()
  const [_shareSetting_, _setShareSetting_] = useState(true)
  const [_currentMapLayer_, _setCurrentMapLayer_] = useState("พิกัด");
  const [_mapLevel_, _setMapLevel_] = useState({
    mode: "country",
    level: "country",
    properties: {}
  })
  const [_utmLocation_, _setUtmLocation_] = useState({
    x: "",
    y: "",
    zone: ""
  });
  const [_cardColor_, _setCardColor_] = useState({
    background: {
      r: "99",
      g: "149",
      b: "249",
      a: "1"
    },
    title: {
      r: "0",
      g: "0",
      b: "0",
      a: ".85"
    },
    number: {
      r: "255",
      g: "255",
      b: "255",
      a: "1"
    }
})

  return (
    <WebParameterContext.Provider
      value={{
        _baseUrl_,
        _setBaseUrl_,
        _page_,
        _setPage_,
        _pageSize_,
        _setPageSize_,
        _filter_,
        _setFilter_,
        _isCluster_,
        _setIsCluster_,
        _embed_,
        _setEmbed_,
        _chartFilter_,
        _setChartFilter_,
        _selectedState_,
        _setSelectedState_,
        _chartInfoEmbed_,
        _setChartInfoEmbed_,
        _chartType_,
        _setChartType_,
        _chart_,
        _setChart_,
        _mainFilter_,
        _setMainFilter_,
        _selectedStateFromShare_,
        _setSelectedStateFromShare_,
        _currBaseMap_,
        _setCurrBaseMap_,
        _lineSort_,
        _setLineSort_,
        _barSort_,
        _setBarSort_,
        _location_,
        _setLocation_,
        _scoreCardLabel_,
        _setScorecardLabel_,
        _sorter_,
        _setSorter_,
        _tableSorter_,
        _setTableSorter_,
        _mapFilter_,
        _setMapFilter_,
        _mapFilterStr_,
        _setMapFilterStr_,
        _tooltipField_,
        _setTooltipField_,
        _limitOffsetMap_,
        _setLimitOffsetMap_,
        _province_,
        _setProvince_,
        _provinceList_,
        _setProvinceList_,
        _district_,
        _setDistrict_,
        _districtList_,
        _setDistrictList_,
        _subDistrictList_,
        _setSubDistrictList_,
        _subDistrict_,
        _setSubDistrict_,
        _region_,
        _setRegion_,
        _chartHeatmapFilter_,
        _setChartHeatmapFilter_,
        _mapLevel_,
        _setMapLevel_,
        _barChartType_,
        _setBarChartType_,
        _utmLocation_,
        _setUtmLocation_,
        _cardColor_,
        _setCardColor_,
        _selectedLbState_,
        _setSelectedLbState_,
        _selectedPieState_,
        _selectedBarState_,
        _setSelectedPieState_,
        _setSelectedBarState_,
        _selectedScatterTreemapState_,
        _setSelectedScatterTreemapState_,
        _selectedScorecardState_,
        _setSelectedScorecardState_,
        _selectedHeatmapState_,
        _setSelectedHeatmapState_,
        _selectedSunburstState_,
        _setSelectedSunburstState_,
        _selectedSunburstStateFromShare_,
        _setSelectedSunburstStateFromShare_,
        _selectedLbStateFromShare_,
        _setSelectedLbStateFromShare_,
        _selectedPieStateFromShare_,
        _selectedBarStateFromShare_,
        _setSelectedPieStateFromShare_,
        _setSelectedBarStateFromShare_,
        _selectedScatterTreemapStateFromShare_,
        _setSelectedScatterTreemapStateFromShare_,
        _selectedScorecardStateFromShare_,
        _setSelectedScorecardStateFromShare_,
        _selectedHeatmapStateFromShare_,
        _setSelectedHeatmapStateFromShare_,
        _zoomLevel_,
        _setZoomLevel_,
        _coordinate_,
        _setCoordinate_,
        _latitude_,
        _setLatitude_,
        _longitude_,
        _setLongitude_,
        _showBack_,
        _setShowBack_,
        _showEdit_,
        _setShowEdit_,
        _showZoom_,
        _setShowZoom_,
        _showType_,
        _setShowType_,
        _showInfo_,
        _setShowInfo_,
        _titleFont_,
        _setTitleFont_,
        _sharedTitle_,
        _setSharedTitle_,
        _showFilter_,
        _setShowFilter_,
        _shareSetting_,
        _setShareSetting_,
        _currentMapLayer_,
        _setCurrentMapLayer_
      }}
    >
      {props.children}
    </WebParameterContext.Provider>
  );
};

export default WebParameterProvider;
